import React, { useState, useEffect } from 'react';
import MessageList from './components/MessageList';
import MessageInput from './components/MessageInput';
import Sidebar from './components/Sidebar';
import { CreateMLCEngine } from "@mlc-ai/web-llm";
import { v4 as uuidv4 } from 'uuid';
import { Agent, Message } from './types';

interface InitProgress {
  progress: number;
  timeElapsed: number;
  text: string;
}

function App() {
  const [messages, setMessages] = useState<Message[]>([{ id: uuidv4(), text: "Hello! Add an agent to the chat, and start talking!", role: 'assistant' }]);
  const [engine, setEngine] = useState<any>(null);
  const [loadingProgress, setLoadingProgress] = useState<InitProgress | null>(null);
  const selectedModel = "gemma-2-2b-it-q4f16_1-MLC";
  
  const [agents, setAgents] = useState<{ [name: string]: Agent }>({
    Bah: { name: "Bah", description: "Bah is an AI agent mimicking a person that is slightly funny.", selected: false },
    Bee: { name: "Bee", description: "Bee is an AI agent mimicking a person that does not like jokes.", selected: false },
    Hoof: { name: "Hoof", description: "Hoof is an AI agent mimicking a person that does not like people.", selected: false },
    Horn: { name: "Horn", description: "Horn is an AI agent mimicking a person t that likes to party.", selected: false },
    Jex: { name: "Jex", description: "Jex is an AI agent mimicking a person that is philosofical, maybe too much.", selected: false },
    Kex: { name: "Kex", description: "Kex is an AI agent mimicking a person that is very serious.", selected: false },
    Kix: { name: "Kix", description: "Kix is an AI agent mimicking a person that is a genius mrketing professional.", selected: false },
    Twistie: { 
      name: "Twistie",
      description: "Twistie is an AI agent mimicking a person that read recently some of the Twistag case-studies and finds them interesting.",
      selected: false,
      context: [
        { 
          description: "Worthmed case study",
          content: "Twistag collaborated with Value for Health CoLAB to develop Worthmed, a platform designed to streamline clinical study design, deployment, and monitoring for the MedTech sector. This multi-tenant solution addresses the challenge of coordinating efforts between MedTech developers, researchers, and regulatory bodies by offering tools for real-time collaboration, secure data handling, and regulatory compliance. Built with .NET Core and Blazor WebAssembly, Worthmed accelerates clinical study cycles, reducing the time needed for MedTech validation while ensuring alignment with European healthcare standards."
        },
        { 
          description: "Joyraft case study",
          content: "Twistag collaborated with Joyraft to build a cutting-edge social discovery app from the ground up, designed to bring people together through curated events and shared experiences. This wasn’t just about building another app, it was about crafting a platform with purpose, a place where connections are intentional, and events are aligned with individual interests."
        },
      ]
    },
  });

  const selectAgent = (selectedAgent: Agent) => {
    setAgents(prevAgents => ({
      ...prevAgents,
      [selectedAgent.name]: {
        ...prevAgents[selectedAgent.name],
        selected: !prevAgents[selectedAgent.name].selected
      }
    }));
  };

  useEffect(() => {
    const initEngine = async () => {
      const maxRetries = 20;
      let attempt = 0;

      while (attempt < maxRetries) {
        try {
          const initProgressCallback = (initProgress: InitProgress) => {
            console.log(initProgress);
            setLoadingProgress(initProgress);
          };

          const engineInstance = await CreateMLCEngine(
            selectedModel,
            { initProgressCallback: initProgressCallback }
          );
          setEngine(engineInstance);
          setLoadingProgress(null); // Clear loading progress once done
          console.log("Engine initialized");
          break; // Exit the loop if initialization is successful
        } catch (error) {
          console.error(`Initialization failed on attempt ${attempt + 1}:`, error);
          alert("Initialization failed. Please check the console for more information.");
          attempt++;
          if (attempt < maxRetries) {
            console.log(`Retrying initialization... (${attempt}/${maxRetries})`);
            await new Promise(resolve => setTimeout(resolve, 2000)); // Wait for 2 seconds before retrying
          } else {
            console.error("Max retries reached. Initialization failed.");
            alert("Max retries reached. Initialization failed.");
          }
        }
      }
    };

    initEngine();
  }, []);

  const addMessage = (text: string, role: 'user' | 'assistant', agent?: string) => {
    const newMessage: Message = { id: uuidv4(), text, role, agent };
    setMessages(items => [...items, newMessage]);
  };

  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-100 overflow-hidden">
      <div className="sm:w-full md:w-1/3">
        <Sidebar reset={() => setMessages([])} modelName={selectedModel} loadingProgress={loadingProgress} agents={Object.values(agents)} selectedAgentCallback={selectAgent}/>
      </div>
      <div className="flex justify-center items-center w-full p-4">
        <div className="relative w-full max-w-md h-full bg-white shadow-lg rounded-3xl border border-gray-300 overflow-hidden">
          <div className="absolute top-0 left-0 w-full h-6 bg-gray-800 rounded-t-3xl"></div>
          <div className="absolute top-1 left-1/2 transform -translate-x-1/2 w-24 h-1 bg-gray-800 rounded"></div>
          <div className="absolute top-2 left-1/2 transform -translate-x-1/2 w-12 h-1 bg-gray-800 rounded"></div>
          <div className="absolute bottom-0 left-0 w-full h-6 bg-gray-800 rounded-b-3xl"></div>
          <div className="absolute bottom-1 left-1/2 transform -translate-x-1/2 w-24 h-1 bg-gray-800 rounded"></div>
          <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 w-12 h-1 bg-gray-800 rounded"></div>
          <div className="flex flex-col h-full">
            <MessageList messages={messages} engine={engine} activeAgents={Object.values(agents).filter(agent => agent.selected)}/>
            <MessageInput addMessage={addMessage} engine={engine} history={messages} activeAgents={Object.values(agents).filter(agent => agent.selected)}/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;

// The model `gemma-2-2b-it-q4f16_1-MLC` is a specific configuration of the gemma-2 model.
// - "IT" stands for instruction tuned, meaning the model has been fine-tuned with specific instructions to improve its performance on certain tasks.
// - "q4" indicates that the model is quantized to 4 bits, which helps in reducing the model size and computational requirements.
// - "f16" refers to the use of float16 precision, which is a half-precision floating-point format that can speed up computation and reduce memory usage.
// For more information, visit the gemma URL: [gemma URL]
